$.fn.nl_lib_ripple = function () {
    $(this).each(function(){
        $(this).addClass("mod--lib-ripple");
    }).on("click", function(event){
        let surface = $(this);

        if (surface.find(".mod--lib-ripple-inner").length === 0) {
            surface.prepend("<div class='mod--lib-ripple-inner'></div>");
        }

        let ink = surface.find(".mod--lib-ripple-inner");

        ink.removeClass("animated");

        if (!ink.height() && !ink.width()) {
            let d = Math.max(surface.outerWidth(), surface.outerHeight());
            ink.css({height: d, width: d});
        }

        let x = event.pageX - surface.offset().left - (ink.width() / 2),
            y = event.pageY - surface.offset().top - (ink.height() / 2),
            rippleColor = surface.data("ripple-color");

        ink.css({
            top: y + 'px',
            left: x + 'px',
            background: rippleColor
        }).addClass("animated");
    });
};