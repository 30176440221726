(function($) {
    let fn = $(".part_ui_wsw");

    if (fn.length) {
        fn.find("table").each(function() {
            $(this).wrap("<div class='elm_table'></div>");
        });
        fn.find('iframe').filter(function(){
            return this.src.match(/youtube\.com/i);
        }).wrap("<div class='elm_video'></div>");
    }
})(jQuery);