(function($) {
    const fn = $(".comp_partners");

    if (fn.length) {
        $.getScript(cdnjs.slick).done(function(){
            fn.find("[data-slider]").on('init', function(){
                $(this).find(".part_ui_btn").nl_lib_ripple();
            }).slick({
                infinite: true,
                slidesToShow: 6,
                slidesToScroll: 1,
                speed: 500,
                pauseOnHover: true,
                arrows: true,
                prevArrow: '<button type="button" class="part_ui_btn mod--circle slick-prev" aria-label="Prev"><i class="icon--chevron-left"></i></button>',
                nextArrow: '<button type="button" class="part_ui_btn mod--circle slick-next" aria-label="Next"><i class="icon--chevron-right"></i></button>',
                dots: false,
                swipeToSlide: true,
                responsive: [
                    {
                        breakpoint: 1439,
                        settings: {
                            slidesToShow: 5
                        }
                    },
                    {
                        breakpoint: 1279,
                        settings: {
                            slidesToShow: 4
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            })
        });
    }
})(jQuery);